import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import axiosInstance from '../../../axiosInstance';
import Button from '../../../components/formComponents/Button';
import ImageInfiniteScroll from '../../../components/ImagesInfiniteScroll';
import FileUpload from '../../../components/FileUpload';
import Edit from '../../../assets/icons/Edit';
import Upload from '../../../assets/icons/Upload';

import ModalBox from '../../../components/ModalBox';
import { inputs } from './Main';
import MapLocation from '../../../components/MapLocation';
import PieGraph from '../../../components/PieChart';
import Loading from '../../../components/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faPlay } from '@fortawesome/free-solid-svg-icons';
import useRefreshToken from '../../../hooks/useRefreshToken';
import Input from '../../../components/formComponents/Input';
import { debounce } from 'lodash';

function View({ getImageColorCategory }) {
  const { id } = useParams();
  const [file, setFile] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [project, setProject] = useState([]);
  const [items, setItems] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [morePhoto, setMorePhoto] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [imageSelect, setImageSelect] = useState(null);
  const [statistics, setStatistics] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('In Process');
  const [error, setError] = useState(null);
  const [page, setPage] = useState(2);
  const [searchTerm, setSearchTerm] = useState('');

  const imageRefs = useRef([]);

  const project_id = parseInt(id);

  const categories = ['In Process', 'Healthy', 'Not Healthy'];

  const refreshToken = useRefreshToken();

  const getCategoryColor = (categoryName) => {
    if (categoryName === 'In Process') {
      return 'bg-orange-600';
    } else if (categoryName === 'Healthy') {
      return 'bg-green-600';
    } else if (categoryName === 'Not Healthy') {
      return 'bg-red-600';
    }
  };

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const handleEdit = () => {
    setOpenEdit((prev) => !prev);
    reset();
  };

  const handleFile = () => {
    setMorePhoto((prev) => !prev);
    reset();
  };

  const handleNextPage = () => {
    fetchData();
    setPage((prevPage) => prevPage + 1);
  };

  const updateData = async (data) => {
    setLoadingModal(true);
    data.megawatt = parseFloat(data.megawatt);
    const { megawatt, name, description } = data;
    const res = await axiosInstance.updateData('/projects/' + id, {
      megawatt,
      name,
      description,
    });
    if (res.project) {
      setProject(res.project);

      toast.success('Project was updated successfully!');
      handleEdit();
    } else {
      toast.error(res.message);
    }
    setLoadingModal(false);
  };

  const onSubmit = async (data) => {
    setLoadingFile(true);
    setUploadProgress(0);

    const fileArray = Array.from(file);
    const totalBytes = fileArray.reduce((acc, curr) => acc + curr.size, 0);
    let previousUploadedBytes = 0;
    let totalUploadedImages = 0;

    const updateProgress = (progressEvent, chunkSize) => {
      const chunkProgress = progressEvent.loaded;

      const totalUploadedBytes = previousUploadedBytes + chunkProgress;

      if (totalUploadedImages % 100 === 0) {
        refreshToken();
      }
      totalUploadedImages += chunkSize;
      const percentCompleted = Math.min(
        Math.round((totalUploadedBytes / totalBytes) * 100), // Calculate the overall progress
        100,
      );

      setUploadProgress(percentCompleted);
    };

    const uploadFiles = async (fileChunk) => {
      const formData = new FormData();
      fileChunk.forEach((file) => {
        formData.append('files', file);
      });
      formData.append('id', project_id);

      try {
        await axiosInstance.postData('/projects/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (progressEvent) => {
            updateProgress(progressEvent, fileChunk.length);
          },
        });

        const chunkBytes = fileChunk.reduce((acc, file) => acc + file.size, 0);
        previousUploadedBytes += chunkBytes;
      } catch (err) {
        toast.error(`Error uploading files: ${err.message}`);
        throw err;
      }
    };

    for (let i = 0; i < fileArray.length; i += 10) {
      const fileChunk = fileArray.slice(i, i + 10);
      await uploadFiles(fileChunk);
    }

    setMorePhoto(false);
    setFile([]);
    fetchData(1, true);
    setLoadingFile(false);
  };

  const handleFileChange = (event) => {
    setFile(event.target.files.length > 0 ? event.target.files : null);
  };
  const fetchData = useCallback(
    (ispage, isUpload) => {
      if (isLoading) return;
      setIsLoading(true);
      axiosInstance
        .getData(
          `/projects/images/${id}?page=${ispage || page}&limit=${
            selectedCategory === 'Not Healthy' ? '9999' : '30'
          }${
            selectedCategory === 'In Process'
              ? '&isHealthy=true&isInspected=false'
              : `&isHealthy=${selectedCategory === 'Healthy'}&isInspected=true`
          }`,
        )
        .then((res) => {
          if (isUpload) {
            setItems([...res?.images]);
          } else {
            setItems((prevItems) => [...prevItems, ...res?.images]);
          }
          setHasMore(res.moreImages);
        })
        .catch((err) => {
          toast.error('Error fetching project:', err);
          setError(err);
        });

      setIsLoading(false);
    },
    [id, page, isLoading, selectedCategory, searchTerm],
  );

  useEffect(() => {
    axiosInstance
      .getData(`projects/${project_id}`)
      .then((res) => {
        setProject(res.data);
        setStatistics(res.panelStatistics);
        setSelectedCategory(
          res?.panelStatistics?.processing === 0 ? 'Not Healthy' : 'In Process',
        );
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error('Error fetching project:', err);
      });
  }, [project_id, file]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (loadingFile) {
        const message =
          'You have an ongoing upload. Are you sure you want to leave?';
        event.returnValue = message;
        return message;
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [loadingFile]);

  const handleCategoryChange = (event) => {
    const category = event.target.value;
    setSelectedCategory(category);
    setPage(2);
    setItems([]);
  };

  useEffect(() => {
    if (searchTerm?.length >= 1) {
      handleSearchImage(searchTerm);
    } else {
      fetchData(1, true);
    }
  }, [selectedCategory, searchTerm]);

  const [showImage, setShowImage] = useState(false);
  const [image, setImage] = useState({});

  const handleImageClick = (image) => {
    if (image) {
      setImage(image);
    }
    setShowImage((prev) => !prev);
  };

  const handleImageSelected = (image) => {
    if (image) {
      setImageSelect(image);
      if (selectedCategory !== 'Not Healthy') {
        setSelectedCategory('Not Healthy');
        setPage(1);
        setItems([]);
      }
    }
  };

  const [openDelete, setOpenDelete] = useState(false);

  const handleCloseDelete = (id) => {
    reset();
    setOpenDelete(!openDelete);
  };

  const handleInspection = async (data) => {
    setLoadingFile(true);
    try {
      const res = await axiosInstance.postData('/projects/mail', {
        id: project_id,
        projectName: project.name,
        numberOfFiles: project.imagecounter,
        altitude: data?.altitude,
      });
      if (res) {
        toast.success('Processing has started.');
      } else {
        throw new Error('Failed to start processing');
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoadingFile(false);
    }
  };

  const handleFixes = async (isFixed, id) => {
    const res = await axiosInstance.updateData('/projects/images/' + id, {
      isFixed,
      isHealthy: true,
    });
    if (res) {
      setItems([]);
      fetchData(1);
      toast.success('Images changed  was updated to isFixed!');
    } else {
      toast.error(res.message);
    }
  };

  const handleGeneratePdf = async () => {
    setLoadingPdf(true);
    try {
      const pdfData = await axiosInstance.generateUnhealthyImagesPdf(id);
      const url = window.URL.createObjectURL(
        new Blob([pdfData], { type: 'application/pdf' }),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `unhealthy_images_project_${id}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      toast.success('PDF generated successfully');
    } catch (error) {
      toast.error('Error generating PDF:', error);
    }
    setLoadingPdf(false);
  };

  const handleSearchImage = useCallback(
    debounce(async (inputText) => {
      try {
        const ishealthy = selectedCategory === 'Healthy';
        const isInspected = selectedCategory === 'In Process';
        const response = await axiosInstance.postData(
          '/projects/images/search',
          {
            projectId: project_id,
            searchTerm: inputText,
            isHealthy: !isInspected ? ishealthy : true,
            isInspected: !isInspected,
          },
        );
        setHasMore(false);

        setItems(response.data);
      } catch (error) {
        toast.error('Failed to fetch images');
      }
    }, 500),
    [selectedCategory],
  );

  if (loading) {
    return <Loading fullscreen />;
  }

  return (
    <div className="w-full px-4 h-full">
      <ModalBox
        mode={'edit'}
        title={'Update Project'}
        open={openEdit}
        handleClose={handleEdit}
        handleSubmit={handleSubmit}
        onSubmit={updateData}
        inputs={inputs}
        register={register}
        control={control}
        errors={errors}
        loading={loadingModal}
        data={project}
      />
      <ModalBox
        title={'Upload more images'}
        open={morePhoto}
        handleClose={handleFile}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        uploadProgress={uploadProgress}
        handleFileChange={handleFileChange}
        inputs={[
          {
            name: 'file',
            id: 'file',
            type: 'file',
            placeholder: 'file',
            options: {
              required: 'File is required',
            },
          },
        ]}
        register={register}
        errors={errors}
        loading={loadingFile}
        data={file}
      />
      <ModalBox
        title={'Please choose the altitude model to use for this inspection?'}
        buttonTitle={'Submit'}
        formStyle="flex w-full flex-wrap items-center justify-center gap-y-5 gap-x-3"
        titleStyle="text-lg"
        open={openDelete}
        handleClose={handleCloseDelete}
        handleSubmit={handleSubmit}
        onSubmit={(data) => {
          handleInspection(data);
          handleCloseDelete();
        }}
        inputs={[
          {
            type: 'radio',
            className: 'flex gap-y-3',
            options: [
              {
                text: 'Low Altitude',
                id: 'low',
                name: 'altitude',
                altitude: 'LOW',
                checked: true,
              },
              {
                text: 'High Altitude',
                id: 'high',
                name: 'altitude',
                altitude: 'HIGH',
                checked: false,
              },
            ],
          },
        ]}
        register={register}
        errors={errors}
        loading={loadingModal}
        data={project}
      />
      <div className="w-full  flex flex-col md:flex-row ">
        <div className=" pb-10 w-full md:w-[40%] md:pb-1 flex flex-col justify-start">
          <h1 className="text-center md:text-start text-2xl">
            {project?.name}
          </h1>
          <h3 className="text-center md:text-start pt-2 text-lg w-full">
            {project?.description}
          </h3>
        </div>

        <div className=" w-full md:w-[60%] flex flex-wrap  md:flex-row  justify-center items-center md:justify-end md:items-start gap-4">
          {/* <Tooltip direction="left" > */}
          <button
            className={`flex justify-between items-center gap-3 px-3 py-1 rounded-md group text-base 
    ${
      project?.imagecounter === null || project?.isinspected || loadingFile
        ? 'bg-gray-400 text-gray-500 '
        : 'bg-gray-700 hover:bg-gray-600 text-white cursor-pointer'
    }`}
            onClick={() => handleCloseDelete()}
            disabled={
              project?.imagecounter === null ||
              project?.isinspected ||
              loadingFile
            }
          >
            <FontAwesomeIcon
              icon={faPlay}
              className={`${loadingFile ? 'text-gray-500' : 'text-white'}`}
            />
            {loadingFile ? 'Please wait...' : 'Start AI processing'}
          </button>
          {/* </Tooltip> */}

          {project?.imagecounter > 0 && (
            <button
              className="flex justify-between items-center gap-3 px-3 py-1 rounded-md group bg-gray-700  hover:bg-gray-600 text-base"
              onClick={() => setMorePhoto((prev) => !prev)}
            >
              <Upload className={'text-white'} />
              Upload images
            </button>
          )}
          <button
            className="flex justify-between items-center gap-3 px-3 py-1 rounded-md group bg-gray-700  hover:bg-gray-600 text-base"
            onClick={() => handleEdit()}
          >
            <Edit width="18px" height="18px" fill="#ffff" />
            Edit
          </button>
          {statistics && statistics?.unhealthyPanels > 0 && (
            <button
              disabled={loadingPdf}
              className={`flex justify-between items-center gap-3 px-3 py-1 rounded-md group text-base text-white ${
                loadingPdf
                  ? 'bg-gray-400 text-gray-500 '
                  : 'bg-gray-700 hover:bg-gray-600 cursor-pointer'
              }`}
              onClick={() => handleGeneratePdf()}
            >
              <>
                <FontAwesomeIcon icon={faFilePdf} className="text-white" />
                {loadingPdf ? 'Generating...' : 'Export'}
              </>
            </button>
          )}
        </div>
      </div>
      <div className="flex flex-col-reverse justify-center md:flex-row w-full items-start ">
        <div className="w-full md:w-3/5">
          {project?.imagecounter > 0 ? (
            <div
              className={`justify-center items-center flex-col ${
                hasMore ? ' min-h-screen' : ' '
              }`}
            >
              <div className="flex md:flex-row flex-col justify-between items-end w-full py-4 md:pt-8">
                <div>
                  <PieGraph statistics={statistics} />
                </div>
                <select
                  value={selectedCategory}
                  onChange={handleCategoryChange}
                  className={`text-base font-medium h-full px-3 py-2.5 text-left focus:outline-none rounded-xl ${getCategoryColor(
                    selectedCategory,
                  )}`}
                >
                  {categories.map((category, index) => (
                    <option key={index} value={category}>
                      {category}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex justify-end w-full items-center gap-3 px-3 py-1 rounded-md ">
                <Input
                  id="search"
                  type="text"
                  placeholder="Search by name"
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                  }}
                  className="w-full px-2 py-3 text-lg text-gray-400 rounded-lg border border-solid border-gray-700 focus:border-pink-600  focus:outline-none  bg-primary mb-2"
                />
              </div>
              <ImageInfiniteScroll
                fetchData={handleNextPage}
                items={items}
                imageRefs={imageRefs}
                hasMore={hasMore}
                error={error}
                getImageColorFilter={getImageColorCategory}
                handleImageClick={handleImageClick}
                handleFixes={handleFixes}
                setImage={setImage}
                showImage={showImage}
                image={image}
                imageSelect={imageSelect}
              />
            </div>
          ) : (
            !openEdit && (
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="flex justify-center w-full items-center flex-col py-4 md:py-16 "
              >
                <FileUpload
                  file={file}
                  errors={errors}
                  uploadProgress={uploadProgress}
                  handleFileChange={handleFileChange}
                  loading={loadingFile}
                  register={register}
                />

                <Button
                  type="submit"
                  text={'Upload'}
                  disabled={loadingFile}
                  className="rounded-full px-6"
                />
              </form>
            )
          )}
        </div>
        <div className="flex justify-end items-end flex-col w-full md:w-2/5 md:pl-8 pt-6">
          <div className="pb-2">
            <p>Location: {project?.location}</p>
            <p>MegaWatt: {project?.megawatt}</p>
          </div>
          <MapLocation
            id={id}
            longitude={project?.longitude}
            imageRefs={imageRefs}
            latitude={project?.latitude}
            handleImageClick={handleImageClick}
            setImageSelect={handleImageSelected}
          />
        </div>
      </div>
    </div>
  );
}

export default View;
